import { FC, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Zoom, Slide, Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { useCookies } from "react-cookie";

import { SectionId } from "enums";
import { HOME_PATH, MY_ACCOUNT_PATH } from "router/constants";
import { scrollIntoElementById } from "utils/scroll-into-element-by-id";

import { Logo } from "components/common/logo.component";
import { NavigationList } from "components/common/navigation-list.component";
import { ManageAccountList } from "components/common/manage-account-list.component";
import { SectionContainer } from "components/common/section-container.component";
import { MenuSlidingPanel } from "components/common/menu-sliding-panel.component";

export const Navbar: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const [cookies] = useCookies();

  const isHomePage = location.pathname === HOME_PATH;

  const isMyAccountPage = location.pathname === MY_ACCOUNT_PATH;

  const isUserLoggedInToPortal = cookies.userLoggedIn;

  const [isSlidingPanelVisible, setIsSlidingPanelVisible] = useState(false);
  const [activeLinkId, setActiveLinkId] = useState<SectionId | null>(
    isHomePage
      ? location.hash
        ? (location.hash.replace("#", "") as SectionId)
        : SectionId.HOME_PAGE
      : null
  );

  useEffect(() => {
    if (location.hash) {
      const hashId = location.hash.replace("#", "") as SectionId;

      const searchedId = hashId || activeLinkId;

      scrollIntoElementById(searchedId, "auto");
      location.hash = "";
    }
  }, [activeLinkId, location]);

  useEffect(() => {
    if (!isHomePage) {
      setActiveLinkId(null);
    }
  }, [isHomePage]);

  const openSlidingPanel = () => setIsSlidingPanelVisible(true);

  const closeSlidingPanel = () => setIsSlidingPanelVisible(false);

  const handleLinkClick = (id: SectionId) => {
    id !== SectionId.FAQ && setActiveLinkId(id);
    scrollIntoElementById(id);
    isSlidingPanelVisible && closeSlidingPanel();
  };

  const handleButtonClick = () => setActiveLinkId(null);

  const myAccountlink = useMemo(
    () => (
      <Link
        to={MY_ACCOUNT_PATH}
        className="w-fit h-11 px-4 border border-primary rounded-lg text-sm text-nowrap font-semibold leading-4 cursor-pointer duration-300 bg-primary hover:bg-opacity-90 shadow-bottom-primary-24 text-white flex items-center justify-center"
      >
        {t("navigation.myAccount")}
      </Link>
    ),
    [t]
  );

  return (
    <>
      <nav
        id={SectionId.HOME_PAGE}
        aria-label={t("arialLabels.navigation")}
        className={twMerge(
          "h-20 w-full absolute top-0 left-0 z-10",
          isMyAccountPage && isUserLoggedInToPortal && "md:hidden"
        )}
      >
        <SectionContainer className="flex-row gap-3">
          <Zoom triggerOnce className="flex-1">
            <Logo />
          </Zoom>

          <Zoom triggerOnce className="flex-2 h-full">
            <NavigationList
              activeLinkId={activeLinkId}
              onLinkClick={handleLinkClick}
              listClassName="h-full flex justify-center gap-x-10 px-10 lg:hidden"
            />
          </Zoom>

          <Zoom triggerOnce className="flex-1 flex justify-end">
            {isUserLoggedInToPortal && myAccountlink}
            {!isUserLoggedInToPortal && (
              <ManageAccountList
                listClassName="flex gap-x-5 md:hidden"
                onButtonClick={handleButtonClick}
              />
            )}
          </Zoom>

          <Slide triggerOnce direction="right">
            <Fade triggerOnce delay={200}>
              <FontAwesomeIcon
                icon={faBars}
                className="hidden lg:block absolute sm:relative top-1/2 -translate-y-1/2 sm:translate-y-0 -right-12 sm:right-0 text-3xl text-white hover:text-primary cursor-pointer"
                onClick={openSlidingPanel}
              />
            </Fade>
          </Slide>
        </SectionContainer>
      </nav>

      <MenuSlidingPanel
        activeLinkId={activeLinkId}
        onLinkClick={handleLinkClick}
        isPanelOpen={isSlidingPanelVisible}
        onClose={closeSlidingPanel}
      />
    </>
  );
};
