import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Zoom } from "react-awesome-reveal";

import { handleChatButtonClick } from "utils/chat-button-click";

import { SectionContainer } from "components/common/section-container.component";
import { Subtitle, Title } from "components/common/typography.component";
import { Button } from "components/common/button.component";
import { BlurElement } from "components/common/blur-element.component";
import { ButtonType, SectionId, TextSize } from "enums";

export const ContactUs: FC = () => {
  const { t } = useTranslation();

  return (
    <footer
      id={SectionId.CONTACT_US}
      aria-label={t("common.contactUs")}
      className="py-20 bg-dark relative overflow-hidden"
    >
      <BlurElement className="opacity-10" />

      <SectionContainer>
        <Zoom triggerOnce>
          <Title size={TextSize.SMALL} className="mb-3">
            {t("common.contactUs")}
          </Title>
          <Subtitle className="mb-5">{t("contactUs.feelFree")}</Subtitle>

          <Button
            buttonType={ButtonType.FILLED}
            className="w-fit sm:w-full"
            onClick={handleChatButtonClick}
          >
            {t("common.contactUs")}
          </Button>
        </Zoom>
      </SectionContainer>
    </footer>
  );
};
