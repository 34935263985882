import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Text, Title } from "components/common/typography.component";
import { GuidesWrapper } from "components/common/guides-wrapper.component";

interface Props {
  isSeparatePage?: boolean;
}

export const SakepayGuidePage: FC<Props> = ({ isSeparatePage }) => {
  const { t } = useTranslation();

  return (
    <GuidesWrapper
      guideName="shakepayGuide.title"
      isSeparatePage={isSeparatePage}
    >
      <Text className="mb-4 text-lg">{t("shakepayGuide.inCanada")}</Text>
      <Text className="mb-4 text-lg">{t("shakepayGuide.easyProcess")}</Text>

      <Title className="text-2xl text-left mb-4">
        {t("shakepayGuide.steps")}
      </Title>
      <ul className="list-decimal text-primary ml-4 mb-6">
        <li>
          <Text className="text-lg">{t("shakepayGuide.step1")}</Text>
        </li>
        <li>
          <Text className="text-lg">{t("shakepayGuide.step2")}</Text>
        </li>
        <li>
          <Text className="text-lg">{t("shakepayGuide.step3")}</Text>
        </li>
      </ul>

      <Title className="text-2xl text-left mb-4">
        {t("shakepayGuide.addFunds")}
      </Title>
      <Text className="mb-1 text-lg">{t("shakepayGuide.important")}</Text>
      <ul className="list-disc text-primary ml-4 mb-6">
        <li>
          <Text className="text-lg">{t("shakepayGuide.emailAddress")}</Text>
        </li>
        <li>
          <Text className="text-lg">{t("shakepayGuide.username")}</Text>
        </li>
        <li>
          <Text className="text-lg">{t("shakepayGuide.code")}</Text>
        </li>
      </ul>

      <Title className="text-2xl text-left mb-4">
        {t("shakepayGuide.buyBitcoin")}
      </Title>
      <ul className="list-decimal text-primary ml-4 mb-6">
        <li>
          <Text className="text-lg">{t("shakepayGuide.pressIcon")}</Text>
        </li>
        <li>
          <Text className="text-lg">{t("shakepayGuide.buyAndSell")}</Text>
        </li>
        <li>
          <Text className="text-lg">{t("shakepayGuide.putAmount")}</Text>
        </li>
        <li>
          <Text className="text-lg">{t("shakepayGuide.pressBuyBitcoin")}</Text>
        </li>
        <li>
          <Text className="text-lg">{t("shakepayGuide.cardInfo")}</Text>
        </li>
      </ul>

      <Title className="text-2xl text-left mb-4">
        {t("shakepayGuide.howToSend")}
      </Title>
      <ul className="list-decimal text-primary ml-4 mb-6">
        <li>
          <Text className="text-lg">{t("shakepayGuide.pressSend")}</Text>
        </li>
        <li>
          <Text className="text-lg">{t("shakepayGuide.pasteAddress")}</Text>
        </li>
        <li>
          <Text className="text-lg">{t("shakepayGuide.continue")}</Text>
        </li>
        <li>
          <Text className="text-lg">{t("shakepayGuide.confirm")}</Text>
        </li>
      </ul>
    </GuidesWrapper>
  );
};
