import { SectionId } from "../enums";

type ScrollBehavior = "auto" | "smooth";

export const scrollIntoElementById = (
  id: SectionId,
  behavior: ScrollBehavior = "smooth"
) =>
  document.getElementById(id)?.scrollIntoView({
    behavior: behavior,
    block: "start",
  });
