import React, { FC } from "react";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";

import { NavigationLinkProps } from "types";

interface Props extends NavigationLinkProps {
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLLIElement>) => void;
}

export const NavigationLink: FC<Props> = ({
  id,
  name,
  href,
  className,
  onClick,
}) => {
  const { t } = useTranslation();

  const itemStyles =
    "h-full flex justify-center items-center text-base text-white hover:text-primary text-nowrap font-semibold leading-5 cursor-pointer duration-300";

  return (
    <li
      key={id}
      aria-label={t("arialLabels.linkTo", { name })}
      className={twMerge(!href && itemStyles, className)}
      onClick={onClick}
    >
      {href ? (
        <a
          href={href}
          rel="noreferrer noopener"
          target="_blank"
          className={twMerge("block w-full", itemStyles)}
        >
          {t(name)}
        </a>
      ) : (
        t(name)
      )}
    </li>
  );
};
