export const HOME_PATH = "/";

export const MY_ACCOUNT_PATH = "/portal";

export const CREATE_ACCOUNT_PATH = "/create-account";

export const FREE_TRIAL_PATH = "/trial";

export const QUICK_PAYMENT_PATH = "/quick-payment/";

export const SHAKEPAY_GUIDE_PATH = "/shakepay-guide/";

export const CASHAPP_GUIDE_PATH = "/cashapp-guide/";

export const VENMO_CRYPTO_GUIDE_PATH = "/venmo-crypto-guide/";

export const BITBUY_CRYPTO_GUIDE_PATH = "/bitbuy-crypto/";

export const COINSMART_GUIDE_PATH = "/coinsmart-guide/";

export const MOONPAY_GUIDE_PATH = "/moonpay-guide/";

export const CREDIT_CARD_GUIDE_PATH = "/credit-card-guide/";

export const CHAT_PATH = "/apollogroup-chat/";

export const VALIDATE_EMAIL = "/validate/";

export const GUIDES_PATH = "/crypto-general-guides/";
