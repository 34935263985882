import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

import { GuidesData } from "types";
import { guidesData } from "constants/index";

import { BlurElement } from "components/common/blur-element.component";

export const GeneralGuides: FC = () => {
  const { t } = useTranslation();

  const [activeGuideId, setActiveGuideId] = useState("1");

  const onGuideButtonClick = (id: string) => () => setActiveGuideId(id);

  const renderGuideButton = ({ name, id }: GuidesData, index: number) => (
    <li key={id} onClick={onGuideButtonClick(id)} className="flex-1">
      <div
        className={twMerge(
          "flex justify-center items-center w-full h-11 px-4 border rounded-lg text-sm text-nowrap font-semibold leading-4 cursor-pointer duration-300 text-white sm:flex-wrap",
          index % 2 === 0
            ? "bg-primary border-primary hover:bg-opacity-90 hover:border-opacity-90"
            : "bg-secondary-dark border-secondary-dark overflow-hidden relative hover:before:content-[''] hover:before:absolute hover:before:top-0 hover:before:left-0 hover:before:w-full hover:before:h-full hover:before:bg-white hover:before:bg-opacity-5",
          activeGuideId === id && "border-white"
        )}
      >
        <div>{t(name)}</div>
        {id === "6" && <div> {t("common.onlyCanada")}</div>}
      </div>
    </li>
  );

  const activeGuideContent = useMemo(() => {
    const activeGuide = guidesData.find((guide) => guide.id === activeGuideId);
    return activeGuide?.content;
  }, [activeGuideId]);

  return (
    <section
      aria-label={t("arialLabels.shakePayGuide")}
      className="bg-dark flex items-center justify-center"
    >
      <div
        className={twMerge(
          "p-10 pt-32  w-full h-full flex flex-1 flex-col items-center bg-guides bg-center bg-cover bg-no-repeat relative before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-guides-overlay before:bg-opacity-50 before:pointer-events-none overflow-hidden"
        )}
      >
        <BlurElement className="opacity-15 left-[10%] top-[20%]" />
        <BlurElement className="bg-radial-blue opacity-15 left-[90%] top-[70%]" />

        <ul className="w-full max-w-6xl flex gap-5 justify-center items-center flex-wrap z-10">
          {guidesData.map(renderGuideButton)}
        </ul>
        <div className="w-full h-full">{activeGuideContent}</div>
      </div>
    </section>
  );
};
