import { FC } from "react";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button } from "./button.component";
import { CREATE_ACCOUNT_PATH } from "router/constants";
import { ManageAccountButtonsProps } from "types";
import { ButtonType } from "enums";
import { manageAccountButtons } from "constants/index";

interface Props {
  listClassName?: string;
  itemClassName?: string;
  buttonClassName?: string;
  onButtonClick?: () => void;
}

export const ManageAccountList: FC<Props> = ({
  listClassName,
  itemClassName,
  buttonClassName,
  onButtonClick,
}) => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const renderManageAccountButton = ({
    name,
    path,
  }: ManageAccountButtonsProps) => {
    const handleClick = () => {
      onButtonClick?.();
      navigate(path);
    };

    return (
      <li aria-label={name} key={name} className={itemClassName}>
        <Button
          buttonType={ButtonType.FILLED}
          className={twMerge(
            "px-5 bg-white border-white text-primary shadow-none",
            path === CREATE_ACCOUNT_PATH &&
              "bg-primary border-primary text-white",
            buttonClassName
          )}
          onClick={handleClick}
        >
          {t(name)}
        </Button>
      </li>
    );
  };

  return (
    <ul
      aria-label={t("arialLabels.manageAccountButtons")}
      className={twMerge("list-none", listClassName)}
    >
      {manageAccountButtons.map(renderManageAccountButton)}
    </ul>
  );
};
