import { FC } from "react";
import { useTranslation } from "react-i18next";

import { useGetCountryInformation } from "api";
import { Title } from "components/common/typography.component";

export const NotFoundPage: FC = () => {
  const { t } = useTranslation();

  const { data } = useGetCountryInformation();

  return (
    <section aria-label={t("arialLabels.notFound")} className="pt-20 bg-dark ">
      <div className="w-full h-screen px-5 bg-light flex flex-col justify-center items-center">
        <Title className="text-secondary-dark text-[50px] max-w-7xl">
          {t("notFoundPage.leadingProvider")}&nbsp;
        </Title>
        <span className="text-[50px] font-extrabold">{data?.toString()}</span>
      </div>
    </section>
  );
};
