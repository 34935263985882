import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { HOME_PATH } from "../../router/constants";

export const WebsiteTitle = () => {
  const location = useLocation();

  const { t } = useTranslation();

  const REACT_APP_WEBSITE_TITLE = process.env.REACT_APP_WEBSITE_TITLE;
  const pathname = location.pathname.toLowerCase().replace(/[^\w]/g, "");
  const title = t(`titles.${pathname}`);

  const pageTitle = () => {
    if (location.pathname === HOME_PATH) {
      return `${REACT_APP_WEBSITE_TITLE} - ${t("common.watch")}`;
    } else {
      return (
        (title.includes("titles") ? t("navigation.notFoundPage") : title) +
        " - " +
        REACT_APP_WEBSITE_TITLE
      );
    }
  };

  return (
    <Helmet>
      <title>{pageTitle()}</title>
    </Helmet>
  );
};
