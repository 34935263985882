import { FC, useEffect, useMemo } from "react";
import SlidingPane from "react-sliding-pane";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

import { SectionId } from "enums";
import { MY_ACCOUNT_PATH } from "router/constants";

import { Logo } from "./logo.component";
import { NavigationList } from "./navigation-list.component";
import { ManageAccountList } from "./manage-account-list.component";
import { BlurElement } from "./blur-element.component";

interface Props {
  activeLinkId: SectionId | null;
  onLinkClick: (id: SectionId) => void;
  isPanelOpen: boolean;
  onClose: () => void;
}

export const MenuSlidingPanel: FC<Props> = ({
  isPanelOpen,
  activeLinkId,
  onLinkClick,
  onClose,
}) => {
  const { t } = useTranslation();
  const [cookies] = useCookies();

  const isUserLoggedInToPortal = cookies.userLoggedIn;

  const myAccountlink = useMemo(
    () => (
      <Link
        to={MY_ACCOUNT_PATH}
        className="w-fit h-11 px-4 border border-primary rounded-lg text-sm text-nowrap font-semibold leading-4 cursor-pointer duration-300 bg-primary hover:bg-opacity-90 shadow-bottom-primary-24 text-white flex items-center justify-center"
      >
        {t("navigation.myAccount")}
      </Link>
    ),
    [t]
  );

  useEffect(() => {
    if (isPanelOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isPanelOpen]);

  return (
    <SlidingPane
      className={
        "hidden lg:flex lg:!w-[40%] sm:!w-full bg-dark bg-radial flex-col"
      }
      overlayClassName="hidden lg:block !bg-black !bg-opacity-30 z-30"
      isOpen={isPanelOpen}
      onRequestClose={onClose}
      hideHeader
      shouldCloseOnEsc
    >
      <div className="h-20 flex justify-end sm:justify-between items-center py-3">
        <BlurElement className="opacity-10 w-full" />

        <Logo className="hidden sm:block" />

        <div className="p-5 absolute top-1 right-0" onClick={onClose}>
          <FontAwesomeIcon
            icon={faTimes}
            className="text-2xl text-white hover:text-primary cursor-pointer"
          />
        </div>
      </div>

      <div className="flex-1 flex justify-center items-center">
        <NavigationList
          listClassName="flex flex-col"
          itemClassName="py-5 text-2xl [&>a]:text-2xl leading-7"
          onLinkClick={onLinkClick}
          activeLinkId={activeLinkId}
        />
      </div>

      {isUserLoggedInToPortal && myAccountlink}
      {!isUserLoggedInToPortal && (
        <ManageAccountList
          listClassName="hidden md:flex md:flex-col md:gap-y-5"
          onButtonClick={onClose}
        />
      )}
    </SlidingPane>
  );
};
