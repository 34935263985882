import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Text, Title } from "components/common/typography.component";
import { GuidesWrapper } from "components/common/guides-wrapper.component";

interface Props {
  isSeparatePage?: boolean;
}

const addFunds = [
  "cashappGuide.addFundsStep1",
  "cashappGuide.addFundsStep2",
  "cashappGuide.addFundsStep3",
  "cashappGuide.addFundsStep4",
  "cashappGuide.addFundsStep5",
];

const buyBitcoin = [
  "cashappGuide.buyBitcoinStep1",
  "cashappGuide.buyBitcoinStep2",
  "cashappGuide.buyBitcoinStep3",
  "cashappGuide.buyBitcoinStep4",
];

const sendBitcoin = [
  "cashappGuide.sendBitcoinStep1",
  "cashappGuide.sendBitcoinStep2",
  "cashappGuide.sendBitcoinStep3",
  "cashappGuide.sendBitcoinStep4",
  "cashappGuide.sendBitcoinStep5",
  "cashappGuide.sendBitcoinStep6",
  "cashappGuide.sendBitcoinStep7",
  "cashappGuide.sendBitcoinStep8",
];

const verifyIdentity = [
  "cashappGuide.verifyIdentityStep1",
  "cashappGuide.verifyIdentityStep2",
  "cashappGuide.verifyIdentityStep3",
];

export const CashappGuidePage: FC<Props> = ({ isSeparatePage }) => {
  const { t } = useTranslation();

  const renderList = useCallback(
    (item: string) => (
      <li key={item}>
        <Text className="text-lg">{t(item)}</Text>
      </li>
    ),
    [t]
  );

  const additionalGuideContent = useMemo(
    () => (
      <>
        <Title className="text-2xl text-left mb-4">
          {t("cashappGuide.sendBitcoin")}
        </Title>
        <ul
          className="list-decimal text-primary ml-4 mb-6"
          aria-label={t("cashappGuide.sendBitcoin")}
        >
          {sendBitcoin.map(renderList)}
        </ul>

        <Title className="text-2xl text-left mb-4">
          {t("cashappGuide.verifyIdentity")}
        </Title>
        <ul
          className="list-decimal text-primary ml-4 mb-4"
          aria-label={t("cashappGuide.verifyIdentity")}
        >
          {verifyIdentity.map(renderList)}
        </ul>

        <Text className="text-lg mb-6">
          <span className="text-primary">{t("cashappGuide.note")}</span>&nbsp;
          {t("cashappGuide.featuresEnabled")}
        </Text>
      </>
    ),
    [renderList, t]
  );

  return (
    <GuidesWrapper
      guideName="cashappGuide.title"
      className="max-w-4xl"
      isSeparatePage={isSeparatePage}
      additionalContent={additionalGuideContent}
    >
      <Title className="text-2xl text-left mb-4">
        {t("cashappGuide.addFunds")}
      </Title>
      <ul
        className="list-decimal text-primary ml-4 mb-6"
        aria-label={t("cashappGuide.addFunds")}
      >
        {addFunds.map(renderList)}
      </ul>
      <Title className="text-2xl text-left mb-4">
        {t("cashappGuide.buyBitcoin")}
      </Title>
      <Text className="text-lg mb-1">{t("cashappGuide.purchaseBitcoin")}</Text>
      <ul
        className="list-decimal text-primary ml-4"
        aria-label={t("cashappGuide.buyBitcoin")}
      >
        {buyBitcoin.map(renderList)}
      </ul>
      <Text className="text-lg mb-6">
        {t("cashappGuide.purchasingBitcoin")}
      </Text>
    </GuidesWrapper>
  );
};
