import { FC } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { Navbar } from "./navbar.component";
import { ContactUs } from "./contact-us.component";
import { WebsiteTitle } from "../common/website-title.component";
import { CHAT_PATH } from "router/constants";

export const MainLayout: FC = () => {
  const location = useLocation();

  const pathname = location.pathname.toLowerCase().replace(/[^\w]/g, "");
  const isChatPage = pathname===CHAT_PATH.replace(/[^\w]/g, ""); 
   
  return (
  <div>
    <Navbar />

    <Outlet />

    {!isChatPage && <ContactUs />}
    <WebsiteTitle />
  </div>
)};
