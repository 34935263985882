import { FC, useEffect } from "react";

import { IframeContainer } from "components/common/iframe-container.component";
import { LinkModule } from "enums";
import { generateLink } from "utils/generate-link";
import { createAccountIframePath } from "constants/index";

export const CreateAccountPage: FC = () => {
  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, []);

  const link = generateLink({
    module: LinkModule.PORTAL,
    additionalPath: createAccountIframePath,
  });

  return (
    <IframeContainer title="Create Account" link={link} id="createAccount" />
  );
};
