import { RouteObject } from "react-router-dom";

import {
  CREATE_ACCOUNT_PATH,
  FREE_TRIAL_PATH,
  HOME_PATH,
  MY_ACCOUNT_PATH,
  QUICK_PAYMENT_PATH,
  SHAKEPAY_GUIDE_PATH,
  CASHAPP_GUIDE_PATH,
  VENMO_CRYPTO_GUIDE_PATH,
  BITBUY_CRYPTO_GUIDE_PATH,
  COINSMART_GUIDE_PATH,
  MOONPAY_GUIDE_PATH,
  CREDIT_CARD_GUIDE_PATH,
  CHAT_PATH,
  VALIDATE_EMAIL,
  GUIDES_PATH,
} from "./constants";

import { MainLayout } from "components/layout/main-layout.component";
import { HomePage } from "pages/home.page";
import { MyAccountPage } from "pages/my-account.page";
import { CreateAccountPage } from "pages/create-account.page";
import { FreeTrialPage } from "pages/free-trial.page";
import { NotFoundPage } from "pages/not-found.page";
import { QuickPayment } from "pages/quick-payment.page";
import { SakepayGuidePage } from "pages/guides/shakepay-guide.page";
import { CashappGuidePage } from "pages/guides/cashapp-guide.page";
import { VenmoCryptoGuidePage } from "pages/guides/venmo-crypto-guide.page";
import { BitbuyCryptoGuidePage } from "pages/guides/bitbuy-crypto-guide.page";
import { CoinsmartGuidePage } from "pages/guides/coinsmart-guide.page";
import { MoonPayGuidePage } from "pages/guides/moonpay-guide.page";
import { CreditCardGuidePage } from "pages/guides/credit-card-guide.page";
import { ChatPage } from "pages/chat.page";
import { ValidateEmail } from "pages/validate-email.page";
import { GeneralGuides } from "pages/general-guides";

export const routes: RouteObject[] = [
  {
    path: HOME_PATH,
    element: <MainLayout />,
    children: [
      {
        path: HOME_PATH,
        element: <HomePage />,
      },
      {
        path: MY_ACCOUNT_PATH,
        element: <MyAccountPage />,
      },
      {
        path: CREATE_ACCOUNT_PATH,
        element: <CreateAccountPage />,
      },
      {
        path: FREE_TRIAL_PATH,
        element: <FreeTrialPage />,
      },
      {
        path: QUICK_PAYMENT_PATH,
        element: <QuickPayment />,
      },
      {
        path: GUIDES_PATH,
        element: <GeneralGuides />,
      },
      {
        path: SHAKEPAY_GUIDE_PATH,
        element: <SakepayGuidePage isSeparatePage />,
      },
      {
        path: CASHAPP_GUIDE_PATH,
        element: <CashappGuidePage isSeparatePage />,
      },
      {
        path: VENMO_CRYPTO_GUIDE_PATH,
        element: <VenmoCryptoGuidePage isSeparatePage />,
      },
      {
        path: BITBUY_CRYPTO_GUIDE_PATH,
        element: <BitbuyCryptoGuidePage isSeparatePage />,
      },
      {
        path: COINSMART_GUIDE_PATH,
        element: <CoinsmartGuidePage isSeparatePage />,
      },
      {
        path: MOONPAY_GUIDE_PATH,
        element: <MoonPayGuidePage isSeparatePage />,
      },
      {
        path: CREDIT_CARD_GUIDE_PATH,
        element: <CreditCardGuidePage />,
      },
      {
        path: CHAT_PATH,
        element: <ChatPage />,
      },
      {
        path: VALIDATE_EMAIL,
        element: <ValidateEmail />,
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ],
  },
];
