import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

import { ButtonType } from "enums";

interface IProps extends PropsWithChildren {
  className?: string;
  type?: "button" | "submit";
  onClick?: () => void;
  arialLabel?: string;
  buttonType?: ButtonType;
}

export const Button = ({
  className,
  type = "button",
  children,
  onClick,
  arialLabel,
  buttonType = ButtonType.FILLED,
}: IProps) => (
  <button
    aria-label={arialLabel}
    type={type}
    className={twMerge(
      "w-full h-11 px-4 border border-primary rounded-lg text-sm text-nowrap font-semibold leading-4 cursor-pointer duration-300",
      buttonType === ButtonType.OUTLINED &&
        "bg-transparent hover:bg-primary shadow-bottom-primary-08 hover:shadow-bottom-primary-24 text-primary hover:text-white",
      buttonType === ButtonType.FILLED &&
        "bg-primary hover:bg-opacity-90 shadow-bottom-primary-24 text-white",
      className
    )}
    onClick={onClick}
  >
    {children}
  </button>
);
