import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { Slide } from "react-awesome-reveal";

import { MainTitle, Text, Title } from "./typography.component";

interface Props extends PropsWithChildren {
  guideName: string;
  className?: string;
  isSegwitSectionHidden?: boolean;
  isSeparatePage?: boolean;
  additionalContent?: JSX.Element;
}

export const GuidesWrapper: FC<Props> = ({
  children,
  guideName,
  className,
  isSegwitSectionHidden,
  isSeparatePage,
  additionalContent,
}) => {
  const { t } = useTranslation();

  const accountLink = "https://portal.apollogroup.tv/Login";

  return (
    <section
      aria-label={t(guideName)}
      className="flex items-center justify-center"
    >
      <div
        className={twMerge(
          isSeparatePage
            ? "p-10 md:p-6 pt-32  w-full h-full flex flex-1 flex-col items-center bg-guides bg-center bg-cover bg-no-repeat relative before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-guides-overlay before:bg-opacity-50 before:pointer-events-none overflow-hidden"
            : ""
        )}
      >
        <Slide
          triggerOnce
          direction="up"
          className=" flex-1 mt-20 mb-2 w-full flex justify-center items-center"
        >
          <div className={twMerge("max-w-4xl w-full z-10", className)}>
            <MainTitle className="mx-auto mb-6 text-primary text-5xl max-w-full">
              Apollo Group TV - {t(guideName)}
            </MainTitle>

            {children}

            {!isSegwitSectionHidden && (
              <>
                <Title className="mb-4 text-2xl text-left">
                  {t("segwit.title")}
                </Title>
                <Text className="text-lg">{t("segwit.steps")}</Text>
                <ul className="list-decimal text-primary ml-4 mb-6">
                  <li>
                    <Text className="text-lg">
                      {t("segwit.step1")}
                      <a href={accountLink} className="text-primary">
                        {accountLink}
                      </a>
                    </Text>
                  </li>
                  <li>
                    <Text className="text-lg">{t("segwit.step2")}</Text>
                  </li>
                  <li>
                    <Text className="text-lg">{t("segwit.step3")}</Text>
                  </li>
                  <li>
                    <Text className="text-lg">{t("segwit.step4")}</Text>
                  </li>
                  <li>
                    <Text className="text-lg">{t("segwit.step5")}</Text>
                  </li>
                </ul>
              </>
            )}
            {additionalContent}

            <Text className="mx-auto text-md">{t("common.regards")}</Text>
            <Text className="mx-auto text-md">{t("common.supportTeam")}</Text>
          </div>
        </Slide>
      </div>
    </section>
  );
};
