import { FC, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import axios from "axios";

import { ButtonType } from "enums";

import { Button } from "components/common/button.component";
import { MainTitle, Text } from "components/common/typography.component";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const ValidateEmail: FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const requestLink = process.env.REACT_APP_VALIDATE_EMAIL_LINK ?? "";

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!email) {
      setError("verifyEmail.error2");
      return;
    }

    if (!emailRegex.test(email)) {
      setError("verifyEmail.error1");
      return;
    }

    if (!error) {
      const payload = {
        brand: searchParams.get("brand"),
        token: searchParams.get("token"),
        email,
      };

      try {
        const response = await axios.post(requestLink, payload, {
          headers: { "Content-Type": "application/json" },
        });

        if (response.status === 200) {
          window.close();
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <section
      aria-label={t("arialLabels.validateEmail")}
      className="h-screen w-full flex justify-center items-center p-10  bg-dark  bg-guides bg-center bg-cover bg-no-repeat relative before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-guides-overlay before:bg-opacity-50 before:pointer-events-none overflow-hidden"
    >
      <div className="w-1/3 xl:w-1/2 lg:w-full">
        <MainTitle className="mb-5">{t("verifyEmail.title")}</MainTitle>
        <Text className="text-center mb-5">{t("verifyEmail.verify")}</Text>
        <form onSubmit={handleSubmit}>
          <div className="relative">
            <input
              type="text"
              id="email"
              placeholder={t("verifyEmail.email")}
              value={email}
              onChange={handleChange}
              className={twMerge(
                "w-full h-11 px-4 mb-8 border border-white border-opacity-60 rounded-lg text-sm text-nowrap font-semibold leading-4 duration-300 bg-black bg-opacity-80 text-white outline-none caret-primary",
                error && "border-red-600 border-opacity-100"
              )}
            />

            {error && (
              <div className="text-red-600 absolute bottom-2 left-3">
                {t(error)}
              </div>
            )}
          </div>
          <Button buttonType={ButtonType.FILLED} type="submit">
            {t("verifyEmail.submit")}
          </Button>
        </form>
      </div>
    </section>
  );
};
