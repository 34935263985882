import { FC } from "react";

import { Header } from "components/layout/header.component";
import { Services } from "components/layout/services.component";
import { Feedbacks } from "components/layout/feedbacks.component";
import { Pricing } from "components/layout/pricing.component";
// import { SupportedPlatforms } from "components/layout/supported-platforms.component";

export const HomePage: FC = () => {
  return (
    <>
      <Header />
      <Services />
      <Feedbacks />
      <Pricing />
      {/* <SupportedPlatforms /> */}
    </>
  );
};
