import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { GuidesWrapper } from "components/common/guides-wrapper.component";
import { Text, Title } from "components/common/typography.component";

interface Props {
  isSeparatePage?: boolean;
}

const addFunds = [
  "bitbuyCryptoGuide.addFundsStep1",
  "bitbuyCryptoGuide.addFundsStep2",
  "bitbuyCryptoGuide.addFundsStep3",
  "bitbuyCryptoGuide.addFundsStep4",
];

const buyBitcoin = [
  "bitbuyCryptoGuide.buyBitcoinStep1",
  "bitbuyCryptoGuide.buyBitcoinStep2",
  "bitbuyCryptoGuide.buyBitcoinStep3",
];

const sendBitcoin = [
  "bitbuyCryptoGuide.sendBitcoinStep1",
  "bitbuyCryptoGuide.sendBitcoinStep2",
  "bitbuyCryptoGuide.sendBitcoinStep3",
  "bitbuyCryptoGuide.sendBitcoinStep4",
];

export const BitbuyCryptoGuidePage: FC<Props> = ({ isSeparatePage }) => {
  const { t } = useTranslation();

  const renderList = useCallback(
    (item: string) => (
      <li key={item}>
        <Text className="text-lg">{t(item)}</Text>
      </li>
    ),
    [t]
  );

  const additionalGuideContent = useMemo(
    () => (
      <>
        <Title className="text-2xl text-left mb-4">
          {t("bitbuyCryptoGuide.sendBitcoin")}
        </Title>
        <ul
          className="list-decimal text-primary ml-4 mb-6"
          aria-label={t("bitbuyCryptoGuide.sendBitcoin")}
        >
          {sendBitcoin.map(renderList)}
        </ul>
        <Text className="text-lg mb-6">
          <span className="text-primary">{t("bitbuyCryptoGuide.note")}</span>
          &nbsp;
          {t("bitbuyCryptoGuide.ifYouWant")}
        </Text>
      </>
    ),
    [renderList, t]
  );

  return (
    <GuidesWrapper
      guideName="bitbuyCryptoGuide.title"
      className="max-w-5xl"
      isSeparatePage={isSeparatePage}
      additionalContent={additionalGuideContent}
    >
      <Title className="text-2xl text-left mb-4">
        {t("bitbuyCryptoGuide.addFunds")}
      </Title>
      <Text className="text-lg font-bold mb-1">
        {t("bitbuyCryptoGuide.canadianDollars")}
      </Text>
      <ul
        className="list-decimal text-primary ml-4 mb-6"
        aria-label={t("bitbuyCryptoGuide.addFunds")}
      >
        {addFunds.map(renderList)}
      </ul>

      <Text className="text-lg mb-6">
        <span className="text-primary">{t("bitbuyCryptoGuide.note")}</span>
        &nbsp;
        {t("bitbuyCryptoGuide.completeDeposit")}
      </Text>

      <Text className="text-lg mb-1">{t("bitbuyCryptoGuide.after")}</Text>
      <ul
        className="list-disc text-primary ml-4 mb-6"
        aria-label={t("bitbuyCryptoGuide.emails")}
      >
        <li>
          <Text className="text-lg">{t("bitbuyCryptoGuide.email1")}</Text>
        </li>
        <li>
          <Text className="text-lg">{t("bitbuyCryptoGuide.email2")}</Text>
        </li>
      </ul>
      <Text className="text-lg mb-6">{t("bitbuyCryptoGuide.deposit")}</Text>

      <Title className="text-2xl text-left mb-4">
        {t("bitbuyCryptoGuide.buyBitcoin")}
      </Title>
      <ul
        className="list-decimal text-primary ml-4 mb-6"
        aria-label={t("bitbuyCryptoGuide.buyBitcoin")}
      >
        {buyBitcoin.map(renderList)}
      </ul>
    </GuidesWrapper>
  );
};
