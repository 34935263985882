import { FC } from "react";
import { twMerge } from "tailwind-merge";

import { TextSize } from "enums";

interface Props extends React.PropsWithChildren {
  size?: TextSize;
  className?: string;
}

interface TextProps extends React.PropsWithChildren {
  className?: string;
}

const textStyles = {
  [TextSize.DEFAULT]:
    "text-white text-5xl md:text-3xl text-center font-bold w-full max-w-208 sm:max-w-full",
  [TextSize.SMALL]: "text-primary text-xl font-semibold",
};

export const MainTitle: FC<Props> = ({
  children,
  size = TextSize.DEFAULT,
  className,
}) => {
  return <h1 className={twMerge(textStyles[size], className)}>{children}</h1>;
};

export const Title: FC<Props> = ({
  children,
  size = TextSize.DEFAULT,
  className,
}) => {
  return <h2 className={twMerge(textStyles[size], className)}>{children}</h2>;
};

export const Subtitle: FC<Props> = ({
  children,
  size = TextSize.DEFAULT,
  className,
}) => {
  return <p className={twMerge(textStyles[size], className)}>{children}</p>;
};

export const Text: FC<TextProps> = ({ children, className }) => {
  return (
    <p className={twMerge("text-white text-xl font-normal", className)}>
      {children}
    </p>
  );
};
