import { applicationsInfo } from "constants/index";
import { SupportedPlatform } from "enums";
import { DownloadLinksData } from "types";

export const transferToSupportedPlatformsData = (
  downloadLinks: DownloadLinksData
) => {
  const platforms = Object.keys(downloadLinks) as (keyof DownloadLinksData)[];
  const applicationsData = platforms.map((platform) => {
    return {
      ...applicationsInfo[platform as SupportedPlatform],
      downloadLink: downloadLinks[platform],
    };
  });
  return applicationsData;
};
