import { FC } from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  className?: string;
}

export const BlurElement: FC<Props> = ({ className }) => {
  return (
    <div
      className={twMerge(
        "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 size-150 rounded-full opacity-10 bg-radial-red pointer-events-none",
        className
      )}
    />
  );
};
