import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Zoom } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";

import { SectionContainer } from "components/common/section-container.component";
import { Subtitle, Text, Title } from "components/common/typography.component";
import { PricingPackageItem } from "components/common/pricing-package-item.component";
import { BlurElement } from "components/common/blur-element.component";
import { PaymentMethods } from "components/common/payment-methods.component";
import { packagesBenefits, packagesContent } from "constants/index";
import { SectionId, TextSize } from "enums";
import { PackageItemProps } from "types";
import { MY_ACCOUNT_PATH } from "router/constants";

export const Pricing: FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const onSubscribeButtonClick = () => navigate(MY_ACCOUNT_PATH);

  const renderPackagesBenefits = (benefit: string) => (
    <li key={benefit}>
      <Text className="text-opacity-80 leading-[150%]">{t(benefit)}</Text>
    </li>
  );

  const renderPricingItem = (item: PackageItemProps) => (
    <PricingPackageItem
      {...item}
      key={item.days}
      onButtonClick={onSubscribeButtonClick}
    />
  );

  return (
    <section
      id={SectionId.PRICING}
      aria-label={t("pricing.pricingAndPackages")}
      className="py-20 bg-dark relative overflow-hidden"
    >
      <BlurElement className="opacity-15 top-[25%]" />

      <SectionContainer>
        <Zoom triggerOnce>
          <Title className="mb-4">{t("pricing.pricingAndPackages")}</Title>
        </Zoom>
        <Zoom triggerOnce>
          <Subtitle
            size={TextSize.SMALL}
            className="text-white text-xl text-center sm:text-left leading-[150%] font-medium max-w-208 sm:max-w-full mb-4"
          >
            {t("pricing.readyToSubscribe")}
          </Subtitle>
        </Zoom>

        <Zoom triggerOnce>
          <ul
            aria-label={t("pricing.benefits")}
            className="list-disc text-white text-opacity-80 mb-14 sm:ml-4"
          >
            {packagesBenefits.map(renderPackagesBenefits)}
          </ul>
        </Zoom>

        <Zoom triggerOnce>
          <ul
            aria-label={t("pricing.packages")}
            className="list-none max-w-7xl w-full grid grid-cols-4 lg:grid-cols-2 sm:grid-cols-1 gap-8 mb-14"
          >
            {packagesContent.map(renderPricingItem)}
          </ul>
        </Zoom>

        <PaymentMethods />
      </SectionContainer>
    </section>
  );
};
