import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Text, Title } from "components/common/typography.component";
import { GuidesWrapper } from "components/common/guides-wrapper.component";

interface Props {
  isSeparatePage?: boolean;
}

const addFunds = [
  "venmoCryptoGuide.addFundsStep1",
  "venmoCryptoGuide.addFundsStep2",
  "venmoCryptoGuide.addFundsStep3",
];

const buyBitcoin = [
  "venmoCryptoGuide.buyBitcoinStep1",
  "venmoCryptoGuide.buyBitcoinStep2",
  "venmoCryptoGuide.buyBitcoinStep3",
  "venmoCryptoGuide.buyBitcoinStep4",
  "venmoCryptoGuide.buyBitcoinStep5",
];

const sendBitcoin = [
  "venmoCryptoGuide.sendBitcoinStep1",
  "venmoCryptoGuide.sendBitcoinStep2",
  "venmoCryptoGuide.sendBitcoinStep3",
  "venmoCryptoGuide.sendBitcoinStep4",
  "venmoCryptoGuide.sendBitcoinStep5",
  "venmoCryptoGuide.sendBitcoinStep6",
];

export const VenmoCryptoGuidePage: FC<Props> = ({ isSeparatePage }) => {
  const { t } = useTranslation();

  const renderList = useCallback(
    (item: string) => (
      <li key={item}>
        <Text className="text-lg">{t(item)}</Text>
      </li>
    ),
    [t]
  );

  const sendBitcoinContent = useMemo(
    () => (
      <>
        <Title className="text-2xl text-left mb-4">
          {t("venmoCryptoGuide.sendBitcoin")}
        </Title>
        <Text className="text-lg mb-1">{t("venmoCryptoGuide.toSend")}</Text>
        <Text className="text-lg font-bold mb-1">
          {t("venmoCryptoGuide.question")}
        </Text>
        <Text className="text-lg mb-1">{t("venmoCryptoGuide.answer")}</Text>

        <ul
          className="list-decimal text-primary ml-4 mb-6"
          aria-label={t("venmoCryptoGuide.sendBitcoin")}
        >
          {sendBitcoin.map(renderList)}
          <li>
            <Text className="text-lg mb-1">
              {t("venmoCryptoGuide.sendBitcoinStep7")}
            </Text>
            <Text className="text-lg">
              <span className="text-primary">
                {t("venmoCryptoGuide.remember")}&nbsp;
              </span>
              {t("venmoCryptoGuide.cryptoTransfers")}
            </Text>
          </li>
          <li>
            <Text className="text-lg">
              {t("venmoCryptoGuide.sendBitcoinStep8")}
            </Text>
          </li>
        </ul>
      </>
    ),
    [renderList, t]
  );

  return (
    <GuidesWrapper
      guideName="venmoCryptoGuide.title"
      className="max-w-5xl"
      isSeparatePage={isSeparatePage}
      additionalContent={sendBitcoinContent}
    >
      <Title className="text-2xl text-left mb-4">
        {t("venmoCryptoGuide.addFunds")}
      </Title>
      <Text className="text-lg mb-1">{t("venmoCryptoGuide.youCanAdd")}</Text>
      <Text className="text-lg mb-1">{t("venmoCryptoGuide.goAndTap")}</Text>
      <ul
        className="list-decimal text-primary ml-4 mb-1"
        aria-label={t("venmoCryptoGuide.addFunds")}
      >
        {addFunds.map(renderList)}
      </ul>
      <Text className="text-lg mb-6">
        {t("venmoCryptoGuide.noWayToCancel")}
      </Text>

      <Title className="text-2xl text-left mb-4">
        {t("venmoCryptoGuide.buyBitcoin")}
      </Title>
      <Text className="text-lg mb-1">{t("venmoCryptoGuide.toBuyBitcoin")}</Text>
      <ul
        className="list-decimal text-primary ml-4 mb-6"
        aria-label={t("venmoCryptoGuide.buyBitcoin")}
      >
        {buyBitcoin.map(renderList)}
      </ul>
    </GuidesWrapper>
  );
};
