import { FC } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { Slide } from "react-awesome-reveal";

import { SectionContainer } from "components/common/section-container.component";
import { Text, Title } from "components/common/typography.component";
import { BlurElement } from "components/common/blur-element.component";
import { ServiceItemProps } from "types";
import { SectionId } from "enums";
import { servicesContent } from "constants/index";

export const Services: FC = () => {
  const { t } = useTranslation();

  const renderServiceItem = (
    { title, text, image }: ServiceItemProps,
    index: number
  ) => (
    <li
      key={title}
      className={twMerge(
        "flex lg:flex-col items-center justify-between gap-x-5 lg:gap-y-14 mb-14 last:mb-0 relative",
        index % 2 && "flex-row-reverse"
      )}
    >
      {index === 1 && (
        <BlurElement className="bg-radial-blue opacity-10 left-[40%] top-[10%]" />
      )}
      {index === 2 && (
        <BlurElement className="opacity-10 left-[75%] top-[70%]" />
      )}

      <Slide triggerOnce direction={index % 2 ? "right" : "left"}>
        <div>
          <Title className="mb-5 text-left max-w-150 lg:w-full">
            {t(title)}
          </Title>
          <Text className="text-lg text-opacity-60 font-medium max-w-150 lg:w-full">
            {t(text)}
          </Text>
        </div>
      </Slide>
      <Slide triggerOnce direction={index % 2 ? "left" : "right"}>
        <img src={image} alt={t(title)} width="540px" className="w-[540px]" />
      </Slide>
    </li>
  );

  return (
    <>
      <section
        id={SectionId.SERVICES}
        aria-label={t("navigation.services")}
        className="py-20 bg-dark overflow-hidden"
      >
        <SectionContainer>
          <ul className="list-none max-w-7xl">
            {servicesContent.map(renderServiceItem)}
          </ul>
        </SectionContainer>
      </section>
    </>
  );
};
