import { FC } from "react";
import { twMerge } from "tailwind-merge";

import { LogoIcon } from "components/icons/logo.icon";
import { HOME_PATH } from "router/constants";

interface Props {
  className?: string;
}

export const Logo: FC<Props> = ({ className }) => (
  <a
    href={HOME_PATH}
    rel="noreferrer noopener"
    className={twMerge("w-fit flex justify-center items-center", className)}
  >
    <LogoIcon width={60} />
  </a>
);
