import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { Zoom } from "react-awesome-reveal";

import { SectionContainer } from "components/common/section-container.component";
import { Button } from "components/common/button.component";
import { BlurElement } from "components/common/blur-element.component";
import {
  MainTitle,
  Subtitle,
  Text,
} from "components/common/typography.component";
import { ButtonType, TextSize } from "enums";
import { useGetCountryInformation } from "api";
import { CREATE_ACCOUNT_PATH } from "router/constants";

export const Header: FC = () => {
  const { t } = useTranslation();

  const { data: countryName } = useGetCountryInformation();

  const navigate = useNavigate();

  const handleButtonClick = () => navigate(CREATE_ACCOUNT_PATH);

  return (
    <header
      className={twMerge(
        "bg-gray-200 h-screen bg-header bg-center bg-cover bg-no-repeat py-2 relative before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-vertical-dark before:bg-opacity-50 before:pointer-events-none overflow-hidden"
      )}
    >
      <BlurElement className="opacity-15 left-[10%] top-[20%]" />
      <BlurElement className="bg-radial-blue opacity-15 left-[90%] top-[70%]" />
      <SectionContainer>
        <Zoom
          triggerOnce
          className="flex flex-col justify-center items-center flex-1 mt-20 mb-2"
        >
          <div className="flex flex-col justify-center items-center">
            <Subtitle size={TextSize.SMALL} className="text-white mb-5">
              {t("header.anywhereAnytime")}
            </Subtitle>

            <MainTitle className="mb-6">
              {t("header.leadingProvider", { countryName })}
            </MainTitle>

            <Text className="text-xl text-center font-medium mb-5">
              {t("header.accessYourFavorite")}
              <sup>*</sup>
            </Text>

            <Button
              buttonType={ButtonType.FILLED}
              className="w-fit sm:w-full px-10"
              onClick={handleButtonClick}
            >
              {t("header.startNow")}
            </Button>
          </div>
        </Zoom>

        <Zoom triggerOnce>
          <Text className="font-semibold text-opacity-40 text-center">
            {t("header.note")}
          </Text>
        </Zoom>
      </SectionContainer>
    </header>
  );
};
