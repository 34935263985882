import { FC } from "react";
import { twMerge } from "tailwind-merge";

interface Props extends React.PropsWithChildren {
  className?: string;
}

export const SectionContainer: FC<Props> = ({ children, className }) => {
  return (
    <div
      className={twMerge(
        "w-full h-full px-24 sm:px-4 flex flex-col justify-center items-center",
        className
      )}
    >
      {children}
    </div>
  );
};
