import { FC } from "react";
import { Zoom } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";

import { paymentMethodsContent } from "constants/index";
import { Text } from "./typography.component";
import { PaymentMethodsProps } from "types";

export const PaymentMethods: FC = () => {
  const { t } = useTranslation();
  const renderPaymentMethods = ({ name, image }: PaymentMethodsProps) => (
    <li key={name} className="flex items-center">
      <img src={image} alt={name} className="w-14 lg:w-10 md:w-8 opacity-60" />
      <Text className="font-semibold text-4xl lg:text-2xl md:text-xl text-white text-opacity-60 text-nowrap ml-3 xs:hidden">
        {name}
      </Text>
    </li>
  );

  return (
    <Zoom triggerOnce>
      <ul
        aria-label={t("arialLabels.paymentMethods")}
        className="list-none max-w-7xl w-full flex justify-evenly gap-x-5"
      >
        {paymentMethodsContent.map(renderPaymentMethods)}
      </ul>
    </Zoom>
  );
};
