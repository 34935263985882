import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

export const ChatPage: FC = () => {
  const { t } = useTranslation();

  useEffect(()=>{
    window.fcWidget.open();    
  },[]);

  return (
    <section
      aria-label={t("arialLabels.shakePayGuide")}
    className="h-screen w-full bg-dark  bg-guides bg-center bg-cover bg-no-repeat relative before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-guides-overlay before:bg-opacity-50 before:pointer-events-none overflow-hidden"
    />
  );
};
