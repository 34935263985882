import React from "react";
import { Helmet } from "react-helmet-async";

export const GoogleTagManager = () => {
  const REACT_APP_GOOGLE_TAG_MANAGER_ID =
    process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

  return (
    <Helmet>
     <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${REACT_APP_GOOGLE_TAG_MANAGER_ID}`}
      ></script>
      <script>
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${REACT_APP_GOOGLE_TAG_MANAGER_ID}');
        `}
      </script>
    </Helmet>
  );
};
