import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Text, Title } from "components/common/typography.component";
import { GuidesWrapper } from "components/common/guides-wrapper.component";

interface Props {
  isSeparatePage?: boolean;
}

export const CoinsmartGuidePage: FC<Props> = ({ isSeparatePage }) => {
  const { t } = useTranslation();

  return (
    <GuidesWrapper
      guideName="coinsmartGuide.title"
      isSeparatePage={isSeparatePage}
    >
      <Title className="text-2xl text-left mb-4">
        {t("coinsmartGuide.steps")}
      </Title>

      <ul className="list-decimal text-primary ml-4 mb-6">
        <li>
          <Text className="text-lg">{t("coinsmartGuide.step1")}</Text>
        </li>
        <li>
          <Text className="text-lg">{t("coinsmartGuide.step2")}</Text>
        </li>
        <li>
          <Text className="text-lg">{t("coinsmartGuide.step3")}</Text>
        </li>
      </ul>

      <Text className="mb-4 text-lg">{t("coinsmartGuide.thankYou")}</Text>
    </GuidesWrapper>
  );
};
