interface Props {
  module: string;
  additionalPath?: string;
  link?: string;
}
export const generateLink = ({ module, additionalPath, link }: Props) => {
  const baselink = link ?? process.env.REACT_APP_BASE_LINK;

  if (baselink) {
    const generatedLink = baselink.replace("~module~", module);
    return `${generatedLink}${additionalPath ?? ""}`;
  }
  return "";
};
