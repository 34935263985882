import axios from "axios";
import { useQuery } from "@tanstack/react-query";

import {
  ApplicationProps,
  CountryInformationResponse,
  SupportedPlatformsDownloadLinksResponse,
} from "types";
import { transferToSupportedPlatformsData } from "utils/transferToSupportedPlatformsData";
import { generateLink } from "utils/generate-link";

const getCountryInformation = async (): Promise<string | Error> => {
  const { data } = await axios.get<CountryInformationResponse>(
    process.env.REACT_APP_COUNTRY_INFO_LINK ?? ""
  );

  return data.countryName;
};

export const useGetCountryInformation = () => {
  const query = useQuery(["countryInformation"], getCountryInformation, {
    retry: false,
  });
  return query;
};

const getSupportedPlatformsData = async (): Promise<ApplicationProps[]> => {
  const host = window.location.hostname;
  const apiLink =
    process.env.REACT_APP_SUPPORTED_PLATFORMS_DEFAULT_API_LINK ??
    generateLink({
      module: host,
      link: process.env.REACT_APP_SUPPORTED_PLATFORMS_API_LINK,
    });

  const {
    data: { data },
  } = await axios.get<{ data: SupportedPlatformsDownloadLinksResponse }>(
    apiLink
  );

  return transferToSupportedPlatformsData(data.apps);
};

export const useGetSupportedPlatformsData = () => {
  const query = useQuery(["downloadLinks"], getSupportedPlatformsData, {
    retry: false,
  });
  return query;
};
