import { FC, useEffect } from "react";

import { LinkModule } from "enums";
import { generateLink } from "utils/generate-link";

import { IframeContainer } from "components/common/iframe-container.component";

export const MyAccountPage: FC = () => {
  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, []);

  const link = generateLink({ module: LinkModule.PORTAL });

  return <IframeContainer title="My Account" link={link} id="myAccount" />;
};
