import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Text, Title } from "components/common/typography.component";
import { GuidesWrapper } from "components/common/guides-wrapper.component";

interface Props {
  isSeparatePage?: boolean;
}

export const MoonPayGuidePage: FC<Props> = ({ isSeparatePage }) => {
  const { t } = useTranslation();

  const link = "https://www.moonpay.com/";

  return (
    <GuidesWrapper
      guideName="moonpayGuide.title"
      isSeparatePage={isSeparatePage}
    >
      <Title className="text-2xl text-left mb-4">
        {t("moonpayGuide.steps")}
      </Title>

      <ul className="list-decimal text-primary ml-4 mb-6">
        <li>
          <Text className="text-lg">
            {t("moonpayGuide.step1")}&nbsp;
            <a href={link} className="text-primary">
              {link}
            </a>
          </Text>
        </li>
        <li>
          <Text className="text-lg">{t("moonpayGuide.step2")}</Text>
        </li>
        <li>
          <Text className="text-lg">{t("moonpayGuide.step3")}</Text>
        </li>
        <li>
          <Text className="text-lg">{t("moonpayGuide.step4")}</Text>
        </li>
        <li>
          <Text className="text-lg">{t("moonpayGuide.step5")}</Text>
        </li>
        <li>
          <Text className="text-lg">{t("moonpayGuide.step6")}</Text>
        </li>
      </ul>

      <Text className="mb-4 text-lg">{t("moonpayGuide.please")}</Text>
    </GuidesWrapper>
  );
};
