import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Zoom } from "react-awesome-reveal";

import { SectionContainer } from "components/common/section-container.component";
import { Subtitle, Text, Title } from "components/common/typography.component";
import { BlurElement } from "components/common/blur-element.component";
import { TextSize } from "enums";
import { FeedbackItemProps } from "types";
import { feedbacksContent } from "constants/index";

export const Feedbacks: FC = () => {
  const { t } = useTranslation();

  const renderFeedbackItem = ({
    image,
    name,
    city,
    feedback,
  }: FeedbackItemProps) => (
    <li
      key={name}
      aria-label={t("arialLabels.feedbackName", { name })}
      className="px-4 py-8 bg-white flex flex-col gap-y-5 shadow-lg rounded-lg"
    >
      <div className="flex-1">
        <Text className="text-dark text-opacity-80">{t(feedback)}</Text>
      </div>

      <div className="flex items-center gap-x-4">
        <img
          loading="lazy"
          decoding="async"
          width="40"
          height="40"
          src={image}
          alt={name}
        />
        <div aria-label={t("arialLabels.feedbackAuthor")}>
          <Text className="text-dark font-semibold">{name}</Text>
          <Text className="!text-xxs text-dark text-opacity-60">{city}</Text>
        </div>
      </div>
    </li>
  );

  return (
    <section
      aria-label={t("arialLabels.feedbacks")}
      className="py-20 bg-light relative overflow-hidden"
    >
      <BlurElement />
      <Zoom triggerOnce>
        <SectionContainer className="lg:px-10">
          <Title size={TextSize.SMALL} className="mb-2">
            {t("feedbacks.apolloFans")}
          </Title>
          <Subtitle className="text-dark mb-14 max-w-162">
            {t("feedbacks.peopleTrustUs")}
          </Subtitle>

          <ul className="flex md:flex-col gap-x-8 lg:gap-x-4 md:gap-y-8 max-w-7xl">
            {feedbacksContent.map(renderFeedbackItem)}
          </ul>
        </SectionContainer>
      </Zoom>
    </section>
  );
};
