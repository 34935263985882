import { FC } from "react";
import { useTranslation } from "react-i18next";

import { GuidesWrapper } from "components/common/guides-wrapper.component";
import { Text, Title } from "components/common/typography.component";

export const CreditCardGuidePage: FC = () => {
  const { t } = useTranslation();

  const link = "https://portal.apollogroup.tv/";

  return (
    <GuidesWrapper guideName="creditCardGuide.title" isSegwitSectionHidden>
      <Title className="text-2xl text-left mb-4 max-w-full">
        {t("creditCardGuide.steps")}
      </Title>

      <ul className="list-decimal text-primary ml-4 mb-6">
        <li>
          <Text className="text-lg">
            {t("creditCardGuide.step1")}&nbsp;
            <a href={link} className="text-primary">
              {link}
            </a>
          </Text>
        </li>
        <li>
          <Text className="text-lg">{t("creditCardGuide.step2")}</Text>
        </li>
        <li>
          <Text className="text-lg">{t("creditCardGuide.step3")}</Text>
        </li>
        <li>
          <Text className="text-lg">{t("creditCardGuide.step4")}</Text>
        </li>
        <li>
          <Text className="text-lg">{t("creditCardGuide.step5")}</Text>
        </li>
        <li>
          <Text className="text-lg">{t("creditCardGuide.step6")}</Text>
        </li>
        <li>
          <Text className="text-lg">{t("creditCardGuide.step7")}</Text>
        </li>
        <li>
          <Text className="text-lg">{t("creditCardGuide.step8")}</Text>
        </li>
      </ul>
    </GuidesWrapper>
  );
};
