import { FC } from "react";
import { twMerge } from "tailwind-merge";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { NavigationLink } from "./navigation-link.component";
import { HOME_PATH } from "router/constants";
import { navigationLinkContent } from "constants/index";
import { NavigationLinkProps } from "types";
import { SectionId } from "enums";

interface Props {
  listClassName?: string;
  itemClassName?: string;
  activeLinkId: SectionId | null;
  onLinkClick: (id: SectionId) => void;
}

export const NavigationList: FC<Props> = ({
  listClassName,
  itemClassName,
  activeLinkId,
  onLinkClick,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const isHomePage = location.pathname === HOME_PATH;

  const handleClick = (id: SectionId) => () => {
    if (!isHomePage && id !== SectionId.CONTACT_US) {
      navigate(id === SectionId.HOME_PAGE ? HOME_PATH : `/#${id}`);
    }

    onLinkClick(id);
  };

  const renderNavigationLink = (link: NavigationLinkProps) => (
    <NavigationLink
      key={link.id}
      className={twMerge(
        activeLinkId === link.id && "text-primary",
        itemClassName
      )}
      onClick={handleClick(link.id)}
      {...link}
    />
  );

  return (
    <ul
      aria-label={t("arialLabels.navigationLinks")}
      className={twMerge("list-none", listClassName)}
    >
      {navigationLinkContent.map(renderNavigationLink)}
    </ul>
  );
};
